<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
            />

            <!-- Login Graphic -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="90%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/pages/Login_Graphic.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Login to {{ serverConfig.name }} 🚀
                  </p>
                  <p class="mb-2">
                    Please sign in to your account
                  </p>
                </v-card-text>
                <p></p>

                <v-expansion-panels v-model="activeExpansionPanel">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span class="info--text">
                        <v-icon
                          left
                          color="info"
                        >
                          {{ icons.mdiSnowflake }}
                        </v-icon>
                        <strong>Authenticate with Snowflake</strong>
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <!-- Snowflake Oath Login -->
                      <v-card-text>
                        <v-text-field
                          v-model="snowflakeAccount"
                          outlined
                          label="Snowflake Account"
                          placeholder="snowflakeAccount"
                          hide-details="auto"
                          class="mb-6"
                          hint="[snowflake_account].snowflakecomputing.com"
                          persistent-hint
                        ></v-text-field>
                        <v-btn
                          :loading="snowflakeOathLoading"
                          block
                          color="info"
                          type="submit"
                          class="mt-6"
                          @click="snowflakeOath"
                        >
                          Authenticate with Snowflake
                        </v-btn>
                        <v-alert
                          v-if="snowflakeOathError"
                          class="mt-3"
                          type="error"
                          text
                        >
                          {{ snowflakeOathError }}
                        </v-alert>
                        <v-row
                          justify="space-around"
                          class="mt-2"
                        >
                          <v-btn
                            text
                            small
                            @click="setupSnowflakeOauth"
                          >
                            Setup Snowflake OAuth
                          </v-btn>
                        </v-row>
                      </v-card-text>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span class="primary--text">
                        <v-icon
                          left
                          color="primary"
                        >
                          {{ icons.mdiKeyChainVariant }}
                        </v-icon>
                        <strong>Login with Username/Password</strong>
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <!-- username/password login form -->
                      <v-card-text>
                        <v-form
                          ref="loginForm"
                          @submit.prevent="passwordLogin"
                        >
                          <v-text-field
                            v-model="email"
                            outlined
                            label="Email"
                            placeholder="email"
                            :error-messages="errorMessages.email"
                            :rules="[validators.required, validators.emailValidator]"
                            hide-details="auto"
                            class="mb-6"
                            @focus="loginError = null"
                          ></v-text-field>

                          <v-text-field
                            v-model="password"
                            outlined
                            :type="isPasswordVisible ? 'text' : 'password'"
                            label="Password"
                            :error-messages="errorMessages.password"
                            placeholder="Password"
                            :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                            :rules="[validators.required]"
                            hide-details="auto"
                            class="mb-2"
                            @click:append="isPasswordVisible = !isPasswordVisible"
                            @focus="loginError = null"
                          ></v-text-field>

                          <div class="d-flex align-center justify-space-between flex-wrap">
                            <!-- <v-checkbox
                              hide-details
                              label="Remember Me"
                              class="mt-0"
                            >
                            </v-checkbox> -->

                            <!-- forget link -->
                            <router-link
                              :to="{name:'auth-forgot-password'}"
                              class="ms-3"
                            >
                              Forgot Password?
                            </router-link>
                          </div>

                          <v-btn
                            block
                            color="primary"
                            type="submit"
                            class="mt-6"
                          >
                            Login
                          </v-btn>
                        </v-form>
                      </v-card-text>
                      <v-alert
                        v-if="loginError"
                        type="error"
                      >
                        {{ loginError }}
                      </v-alert>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-alert
                  v-if="logoutMessage"
                  class="mt-5"
                  type="error"
                >
                  <strong>{{ logoutMessage }}</strong>
                </v-alert>
                <!-- create new account  -->
                <!-- <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p class="mb-0 me-2">
                    New on our platform?
                  </p>
                  <router-link :to="{name:'auth-register'}">
                    Create an account
                  </router-link>
                </v-card-text> -->

                <!-- divider -->
                <!-- <v-card-text class="d-flex align-center mt-2">
                  <v-divider></v-divider>
                  <span class="mx-5">or</span>
                  <v-divider></v-divider>
                </v-card-text> -->

                <!-- socail links -->
                <!-- <v-card-actions class="d-flex justify-center">
                  <v-btn
                    elevation="0"
                  >
                    <v-img
                      max-width="50%"
                      :src="require(`@/assets/images/logos/sign_in_with_google.png`)"
                    >
                    </v-img>
                  </v-btn>
                </v-card-actions> -->
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
/* eslint-disable operator-linebreak */
import { appUserSignin } from '@/functions/authentication'
import { useRouter } from '@core/utils'
import { emailValidator, required } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline, mdiFacebook, mdiGithub, mdiGoogle, mdiKeyChainVariant, mdiSnowflake, mdiTwitter } from '@mdi/js'
import serverConfig from '@serverConfig'
import { onMounted, ref } from '@vue/composition-api'
import { getOathCode } from '../../functions/snowflakeOauth'

export default {
  setup() {
    // Template Ref
    const loginForm = ref(null)

    const { router } = useRouter()

    const activeExpansionPanel = ref(null)
    const isPasswordVisible = ref(false)

    const email = ref(null)
    const password = ref(null)
    const errorMessages = ref([])
    const loginError = ref(null)
    const snowflakeAccount = ref(null) // bm60497.us-central1.gcp lendio.us-east-1
    const snowflakeOathLoading = ref(false)
    const snowflakeOathError = ref(null)
    const logoutMessage = localStorage.getItem('logoutMessage')
    const socialLink = [
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
    ]
    onMounted(() => {
      // If snowflakeAccountId is saved locally - load it into field
      const snowflakeAccountId = localStorage.getItem('snowflakeAccountId')
      if (snowflakeAccountId) snowflakeAccount.value = snowflakeAccountId
      if (localStorage.getItem('accountEmail')) email.value = localStorage.getItem('accountEmail')

      if (localStorage.getItem('authMethod')) activeExpansionPanel.value = Number(localStorage.getItem('authMethod'))

      // If URL Contains Params for Oauth Pre-Load Values - Should Override LocalStorage
      if (router.currentRoute.query?.oauth) {
        snowflakeAccount.value = router.currentRoute.query.oauth
        activeExpansionPanel.value = 0
      }
    })
    const snowflakeOath = async () => {
      localStorage.removeItem('logoutMessage')
      if (snowflakeAccount.value) {
        // Save SnowflakeAccountId Locally For Future Logins
        localStorage.setItem('authMethod', activeExpansionPanel.value)
        localStorage.setItem('snowflakeAccountId', snowflakeAccount.value)
        snowflakeOathError.value = null
        console.log('Snowflake Auth Initiated....')
        snowflakeOathLoading.value = true
        try {
          const oAuthCode = await getOathCode(snowflakeAccount.value)
          console.log('oAuthCode', oAuthCode)
          if (oAuthCode === 'No Account') {
            snowflakeOathError.value = `This snowflake instance is not part of a ${serverConfig.name} Account. You must setup a ${serverConfig.name} account first.`
          }
          if (oAuthCode === 'No Snowflake Oauth') {
            snowflakeOathError.value = 'Your Snowflake account is not setup for oAuth Authentication'
          }
          snowflakeOathLoading.value = false
        } catch (err) {
          snowflakeOathLoading.value = false
          console.log(err)
          // eslint-disable-next-line operator-linebreak
          snowflakeOathError.value = 'Your Snowflake Account or Username is incorrect.  Also your default role cannot be ACCOUNTADMIN or SECURITYADMIN.'
        }
      }
    }
    const setupSnowflakeOauth = async () => {
      console.log('Setting Up Oauth...')
      localStorage.removeItem('logoutMessage')
      if (snowflakeAccount.value) {
        // Save SnowflakeAccountId Locally For Future Logins
        localStorage.setItem('authMethod', activeExpansionPanel.value)
        localStorage.setItem('snowflakeAccountId', snowflakeAccount.value)
        snowflakeOathError.value = null
        console.log('Snowflake Auth Initiated....')
        snowflakeOathLoading.value = true
        try {
          console.log('Getting Oauth Code....')
          const oAuthCode = await getOathCode(snowflakeAccount.value)
          console.log('OAuth Code', oAuthCode)
          if (oAuthCode === 'No Account') {
            snowflakeOathError.value = `This snowflake instance is not part of a ${serverConfig.name} Account. You must setup a ${serverConfig.name} account first.`
          }
          if (oAuthCode === 'No Snowflake Oauth') {
            router.push('/account-setup/oauth-setup')
          }
          snowflakeOathLoading.value = false
        } catch (err) {
          snowflakeOathLoading.value = false
          console.log(err)
          // eslint-disable-next-line operator-linebreak
          snowflakeOathError.value = 'Your Snowflake Account or Username is incorrect.  Also your default role cannot be ACCOUNTADMIN or SECURITYADMIN.'
        }
      }
    }

    const passwordLogin = async () => {
      localStorage.removeItem('logoutMessage')
      loginError.value = null
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      try {
        localStorage.setItem('accountEmail', email.value)
        localStorage.setItem('authMethod', activeExpansionPanel.value)
        const loginResponse = await appUserSignin(email.value, password.value)
        console.log('loginResponse', loginResponse)
        if (loginResponse.user.uid) {
          localStorage.setItem('loggedInMethod', 'password')
        }

        return
      } catch (err) {
        console.log('There was an error logging you in.', err)
        loginError.value = 'We could not log you in. Please check your username and password.'
        errorMessages.value = err.message
      }
    }

    return {
      serverConfig,
      logoutMessage,
      activeExpansionPanel,
      setupSnowflakeOauth,
      snowflakeOathLoading,
      snowflakeOathError,
      snowflakeOath,
      snowflakeAccount,
      passwordLogin,
      isPasswordVisible,
      email,
      password,
      errorMessages,
      loginError,
      socialLink,
      icons: {
        mdiKeyChainVariant,
        mdiSnowflake,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },

      // Template Refs
      loginForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
